.gf-home-page {
  text-align: center;
}

.gf-profile-image {
  background: url("../../assets/images/profile.jpeg");
  display: block;
  width: 160px;
  height: 160px;
  background-size: 160px;
  margin: 0 auto 2em;
  border-radius: 50%;
}

a {
  color: #3099d1;
}

a:hover {
  text-decoration: underline;
  color: #3099d1;
}

a.button:hover {
  text-decoration: none;
}

.gf-bold {
  font-weight: bold;
}

.container {
  max-width: 720px;
}

h1 {
  margin-bottom: 3rem;
}

ul {
  list-style: disc;
  padding-left: 2em;
}

ol {
  padding-left: 2em;
}

li {
  padding-left: 0.5em;
}

input {
  color: #434649;
}

pre {
  padding-left: 1rem;
  border-left: none;
}

.gf-validation-error {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #9b4dca;
  color: white;
}

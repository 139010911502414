.gf-post-preview {
  margin-bottom: 2em;
}

.gf-post-preview span {
  display: inline-block;
  font-size: 0.9em;
}

.gf-post-preview a {
  font-size: 1.2em;
}

.gf-post-preview a:hover {
  text-decoration: none;
  border-bottom: 1px solid #3099d1;
}

.gf-post-preview a:visited {
  color: #3099d1;
}

.gf-post-preview-published {
  margin-left: 1rem;
  padding: 0 5px;
  border-radius: 4px;
}

.gf-post-preview-published-yes {
  background: #3099d1;
  color: #fff;
}

.gf-post-preview-published-no {
  background: #e2e2e2;
}

.gf-about-image {
  background: url("../../assets/images/about.jpg");
  background-size: 720px 460px;
  background-position: center;
  display: block;
  width: 720px;
  max-width: 100%;
  height: 460px;
  margin-bottom: 2.5em;
}

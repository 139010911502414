.gf-post-toolbar {
  margin-bottom: 2.5rem;
}

.gf-post-toolbar .button {
  margin-right: 1rem;
}

.gf-post li {
  margin-bottom: 0;
}

.gf-post-date {
  padding: 0.5em 0;
  border-top: 1px solid #dbdee0;
  border-bottom: 1px solid #dbdee0;
  color: #606c76;
}

.gf-post .code-toolbar {
  margin-bottom: 2rem;
}
